<template>
  <div class="panel">
    <el-form id="login-form" class="form">
      <el-form-item>
        <el-input
          :placeholder="$t('email')"
          prefix-icon="el-icon-message"
          v-model="email"
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          type="password"
          :placeholder="$t('password')"
          prefix-icon="el-icon-lock"
          v-model="password"
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          @click="login"
          type="primary"
          id="login-button"
          class="submit-button"
          >{{ $t("login") }}</el-button
        >
      </el-form-item>
    </el-form>
    <div class="footer">
      <el-button @click="goSignup" type="text">{{ $t("signup") }}</el-button>
      <el-button @click="goResetPassword" type="text">{{
        $t("resetPassword")
      }}</el-button>
    </div>
    <el-alert
      v-if="loginError"
      :title="loginError.message"
      type="error"
      @close="loginError = null"
    >
    </el-alert>
  </div>
</template>
<script>
import config from "../config";
export default {
  name: "Login",
  props: {
    msg: String,
    jumpto: String,
  },
  data() {
    return {
      username: "",
      email: "",
      password: "",
      loginError: null,
    };
  },
  methods: {
    login() {
      fetch(`${config.lbServer}/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
          password: this.password,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            this.loginError = data.error;
          } else {
            this.loginError = data.error;
            localStorage.lbToken = data.token;

            window.postMessage(`lbToken-${localStorage.lbToken}`, "*");
            if (this.jumpto) {
              console.log(this.jumpto);
              setTimeout(() => {
                location.href = this.jumpto;
              }, 1000);
            } else {
              this.$router.replace("index");
            }
          }
        });
    },
    goSignup() {
      this.$router.push("signup");
    },
    goResetPassword() {
      this.$router.push("reset-password");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "./Form.css";
</style>
