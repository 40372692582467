<template>
  <div class="panel">
    <div v-if="verifySuccess">
      <span>{{$t('verifySuccess')}}, </span>
      <el-button type="text" @click="$router.replace('login')">{{$t('login')}}</el-button>
    </div>
    <el-alert
      v-if="error"
      :title="error.message"
      type="error"
      @close="error = null"
    >
    </el-alert>
    <el-form v-if="needSendAgain" class="form">
      <el-form-item>
        <el-input
          :placeholder="$t('email')"
          prefix-icon="el-icon-message"
          v-model="email"
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          @click="sendAgain"
          type="primary"
          class="submit-button"
          >{{ $t("sendAgain") }}</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import config from "../config";
import {ERROR_CODE_VERIFICATION_CODE_TIMED_OUT} from '../constants'
export default {
  name: "Verify",
  props: {
    msg: String,
    verifyKey: String,
  },
  data() {
    return {
      verifySuccess: false,
      error: null,
      email: ''
    };
  },
  mounted(){
      fetch(`${config.lbServer}/users/verify?verifyKey=${this.verifyKey}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            this.error = data.error;
          } else {
            this.verifySuccess = true
          }
        });
  },
  computed: {
    needSendAgain(){
      return this.error && this.error.code == ERROR_CODE_VERIFICATION_CODE_TIMED_OUT
    }
  },
  methods: {
    sendAgain(){
      this.error = null
      if(this.email){
        fetch(`${config.lbServer}/users/sendEmail?email=${this.email}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            this.error = data.error;
          } else {
            this.$message({
              message: data.message,
              type: 'success'
            })
          }
        });
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "./Form.css";
</style>
