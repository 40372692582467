<template>
  <el-container>
    <el-header class="header" v-if="userInfo">
      <div>{{ $t("hello") }} {{ userInfo.username || userInfo.email }}</div>
      <div>
        <el-button @click="logout">{{ $t("logout") }}</el-button>
      </div>
    </el-header>
    <el-header class="header" v-else>
      <div>{{ $t("notLogined") }}</div>
      <div>
        <el-button @click="goLogin" type="primary">{{ $t("login") }}</el-button>
      </div>
    </el-header>
    <el-main>
      <div v-if="ocr">
        <div class="title-large">用户权益</div>
        <div class="title">OCR</div>
        <div class="text">
          <div>免费{{ ocr.freeCount }}次/每天</div>
          <div>已使用{{ ocr.usedCount }}次</div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import config from "../config";
export default {
  name: "Index",
  props: {
    msg: String,
  },
  data() {
    return {
      userInfo: "",
      ocr: "",
    };
  },
  mounted() {
    if (localStorage.lbToken) {
      fetch(`${config.lbServer}/users/me`, {
        headers: {
          Authorization: "Bearer " + localStorage.lbToken,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            this.$message.error(data.error.message);
          } else {
            this.userInfo = data;
          }
        });
      fetch(`${config.lbServer}/ocr`, {
        headers: {
          Authorization: "Bearer " + localStorage.lbToken,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            this.$message.error(data.error.message);
          } else {
            this.ocr = data;
          }
        });
    }
  },
  methods: {
    goLogin() {
      this.$router.push("login");
    },
    logout() {
      localStorage.removeItem("lbToken");
      this.userInfo = "";
      this.ocr = null;
    },
    getI18n(str) {
      return str;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f9fa;
}
.title{
	border-bottom: 1px solid #f8f9fa;
}
</style>

