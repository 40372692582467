<template>
  <div class="panel">
    <el-form v-if="resetKey" class="form">
      <el-form-item>
        <el-input
          type="password"
          :placeholder="$t('password')"
          prefix-icon="el-icon-lock"
          v-model="password"
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          type="password"
          :placeholder="$t('repassword')"
          prefix-icon="el-icon-lock"
          v-model="repassword"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <el-form v-else class="form">
      <el-form-item>
        <el-input
          :placeholder="$t('email')"
          prefix-icon="el-icon-message"
          v-model="email"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div class="footer">
      <el-button v-if="resetKey" type="text" @click="confirmChange">{{
        $t("submit")
      }}</el-button>
      <el-button v-else type="text" @click="sendEmail">{{
        $t("sendEmail")
      }}</el-button>
    </div>
    <el-alert
      v-if="error"
      :title="error.message"
      type="error"
      @close="error = null"
    >
    </el-alert>
  </div>
</template>
<script>
import config from "../config";
export default {
  name: "ResetPassword",
  props: {
    msg: String,
    resetKey: String,
  },
  data() {
    return {
      email: "",
      password: "",
      repassword: "",
      error: null,
    };
  },
  methods: {
    validate() {
      this.error = null;
      if (this.password != this.repassword) {
        this.error = {
          message: this.$t("error:passwordInconsistent"),
        };
      }
    },
    sendEmail() {
      fetch(`${config.lbServer}/users/reset-password/init`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            this.error = data.error;
          } else {
            this.error = null;
            this.$message({
              message: this.$t("message:emailSuccess"),
              type: "success",
            });
          }
        });
    },
    confirmChange() {
      this.validate();
      !this.error &&
        fetch(`${config.lbServer}/users/reset-password/finish`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            resetKey: this.resetKey,
            password: this.password,
            confirmPassword: this.repassword,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              this.error = data.error;
            } else {
              this.error = null;
              this.$message({
                message: this.$t("message:resetPasswordSuccess"),
                type: "success",
              });
              this.$router.replace("login");
            }
          });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "./Form.css";

#login-btn {
  padding: 12px 20px;
}
</style>


