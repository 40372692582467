import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router'

import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';


import Index from './components/Index.vue'
import Login from './components/Login.vue'
import Verify from './components/Verify.vue'
import SignUp from './components/SignUp.vue'
import ResetPassword from './components/ResetPassword.vue'

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueRouter)
Vue.use(VueI18Next);

const routes = [
  { path: '/login', component: Login, props: route => ({ jumpto: route.query.jumpto }) },
  { path: '/verify', component: Verify, props: route => ({ verifyKey: route.query.verifyKey }) },
  { path: '/signup', component: SignUp },
  { path: '/index', component: Index },
  { path: '/reset-password', component: ResetPassword, props: route => ({ resetKey: route.query.resetKey }) },
  { path: '/', component: Index },
]


i18next.init({
  lng: navigator.language.match('^zh') ? 'zh' : 'en',
  resources: {
    en: {
      translation: {
        username: 'username',
        email: 'email',
        password: 'password',
        repassword: 'repeat the password',
        login: 'login',
        notLogined: 'User is not logged in',
        logout: 'logout',
        signup: 'sign up',
        signuped: 'Signed up',
        resetPassword: 'reset password',
        sendEmail: 'send email',
        submit: 'submit',
        sendAgain: 'send again',
      },
      error: {
        passwordInconsistent: 'The two passwords entered are inconsistent',
      },
      message: {
        emailSuccess: 'The email has been sent, please check it.',
        signupSuccess: 'Registered successfully, please check your email to active the account',
        resetPasswordSuccess: 'Password reset successful',
        verifySuccess: 'Account has been successfully activated'
      }
    },
    zh: {
      translation: {
        username: '用户名',
        email: '邮箱',
        password: '密码',
        repassword: '再次输入密码',
        login: '登录',
        notLogined: '用户未登录',
        logout: '退出',
        signup: '注册',
        signedup: '已注册',
        resetPassword: '重设密码',
        sendEmail: '发送邮件',
        submit: '提交',
        sendAgain: '再次发送',
      },
      error: {
        passwordInconsistent: '两次输入的密码不一致',
      },
      message: {
        emailSuccess: '邮件已发送，请注意查收。',
        signupSuccess: '注册成功，请及时查收并激活账号',
        resetPasswordSuccess: '重置密码成功！',
        verifySuccess: '账户已激活'
      }
    }
  }
});

const i18n = new VueI18Next(i18next);
// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
