<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
body{
  margin: 0;
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  line-height: 1.8;
}
.aux-text{
  font-size: 12px;
}
.text-small{
  font-size: 13px;
}
.text{
  font-size: 14px;
}
.title-small{
  font-size: 16px;
}
.title{
  font-size: 18px;
}
.title-large{
  font-size: 20px;
}

</style>
